<template>
  <div>
    <Header></Header>

    <div id="editArea" class="container-fluid">
      <div style="margin-top: 50px">
        <el-form ref="form" :rules="rules" :model="form" label-width="80px">
          <el-form-item label="标题" prop="title">
            <el-input v-model="form.title" style="width: 50%"></el-input>
            <el-tag
              :key="tag"
              v-for="tag in dynamicTags"
              closable
              :disable-transitions="false"
              @close="handleClose(tag)">
              {{ tag }}
            </el-tag>
            <el-input
              class="input-new-tag"
              v-if="inputVisible"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @keyup.enter.native="handleInputConfirm"
              @blur="handleInputConfirm"
            >
            </el-input>
            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <mavon-editor style="min-height: 450px ;max-height: 800px" v-model="form.content" ref=md @save="save"
                          @imgDel="imgDel"
                          @imgAdd="imgAdd"></mavon-editor>
          </el-form-item>
          <el-form-item>
            <div style="text-align: center;padding-bottom: 50px">
              <el-button type="primary" @click="save" style="width: 150px">立即保存</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import cos from '@/main'

export default {
  name: 'BlogEdit',
  components: { Header },
  data () {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
      studentId: 0,
      blogId: 0,
      form: {
        title: '',
        content: ''
      },
      rules: {
        title: [
          {
            required: true,
            message: '请输入标题',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 15,
            message: '长度在 3 到 15 个字符',
            trigger: 'blur'
          }
        ],
        content: [
          {
            required: true,
            message: '内容不能为空',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.blogId = this.$route.params.blogId
    this.checkLogin()
  },
  methods: {
    handleClose (tag) {
      // this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/removeBlogTag',
        params: {
          blogId: this.blogId,
          tag: tag
        }
      }).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getBlogTags()
        }
      })
    },
    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.createTag(this.blogId, inputValue)
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    createTag (id, tag) {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/createBlogTag',
        params: {
          blogId: id,
          tag: tag
        }
      }).then(res => {
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.getBlogTags()
        }
      })
    },
    getBlogTags () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/showTagsByBlogId',
        params: {
          blogId: this.blogId
        }
      }).then(res => {
        if (res.data.status) {
          this.dynamicTags = res.data.data
        }
      })
    },
    getBlogById () {
      console.log('id:', this.blogId)
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/showBlogById',
        params: {
          id: this.blogId
        }
      }).then(res => {
        if (res.data.status) {
          this.form.title = res.data.data.title
          this.form.content = res.data.data.content
        }
      })
    },
    checkLogin () {
      axios({
        methods: 'GET',
        url: 'https://mooc.kirinnorine.top/student/checkLogin'
      }).then(res => {
        if (res.data.status) {
          this.studentId = res.data.data.id
          this.getBlogById()
          this.getBlogTags()
        }
      })
    },
    save () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/changeContent',
        data: {
          id: this.blogId,
          content: this.form.content
        },
        transformRequest: [
          function (data) {
            let ret = ''
            for (const it in data) {
              ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            ret = ret.substring(0, ret.lastIndexOf('&'))
            return ret
          }
        ],
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then(res => {
        axios({
          method: 'POST',
          url: 'https://mooc.kirinnorine.top/blog/changeTitle',
          params: {
            id: this.blogId,
            title: this.form.title
          }
        })
        if (res.data.status) {
          this.$message.success(res.data.message)
          this.$router.push({
            name: 'BlogDetails',
            params: { blogId: this.blogId }
          })
        }
      })
    },
    imgAdd (pos, $file) {
      // let formdata = new FormData()
      // formdata.append('image', $file)
      console.log('添加图片' + $file + pos)
      console.log($file)
      // this.$refs.md.$img2Url(pos, 'https://yuxue.huangzhaokun.top/')
      console.log(pos)
      const date = new Date()
      const hour = date.getHours()
      let bucket
      if (hour < 8) {
        bucket = 'a'
      } else if (hour < 16) {
        bucket = 'b'
      } else {
        bucket = 'c'
      }
      const fileName = $file.name
      console.log('filename', fileName)
      const keyID = 'student/' + this.studentId + '/' + fileName
      // student/100001/abc.txt
      cos.putObject({
        Bucket: 'mooc-temp-' + bucket + '-1303830952',
        Region: 'ap-shanghai',
        Key: keyID,
        StorageClass: 'STANDARD',
        Body: $file, // 上传文件对象
        onProgress: function (progressData) {
          console.log(JSON.stringify(progressData))
        }
      }, (err, data) => {
        console.log(err || data)
        if (data.statusCode === 200) {
          const location = data.Location
          console.log(location)
          console.log('bucket:' + bucket + 'keyID:' + keyID)
          axios({
            method: 'POST',
            url: 'https://mooc.kirinnorine.top/tempFile/checkUploadedFile',
            params: {
              bucket: bucket.toUpperCase(),
              path: keyID
            }
          }).then(res => {
            if (res.data.status) {
              console.log(res.data)
              axios({
                method: 'POST',
                url: 'https://mooc.kirinnorine.top/blogImage/uploadBlogImage',
                params: {
                  blogId: this.blogId,
                  bucket: bucket.toUpperCase(),
                  path: keyID
                }
              }).then(res => {
                if (res.data.status) {
                  this.$refs.md.$img2Url(pos, res.data.data)
                }
              })
            }
          })
        }
      })
    },
    imgDel (pos) {
      console.log(pos)
    }
  }
}
</script>

<style scoped>
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
</style>
